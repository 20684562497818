import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './UnitSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import help from '../../assets/help-grey.svg';
import ReactTooltip from 'react-tooltip';
import Form from 'react-bootstrap/Form';

const UnitSettingsListTable = ({ changeUnitSetting, intl, UpdateAllowed, unitSettingsData, localformErrors }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div className="">
      <Form>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="modelVersion.settings" defaultMessage="Settings" />
              </th>
              <th>
                <FormattedMessage id="modelVersion.value" defaultMessage="Value" />
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {unitSettingsData.map(n => {
              let tooltip = intl.formatMessage({ id: n.TranslationCode || '0', defaultMessage: n.Description });
              return (
                <Fragment key={n.UnitSettingId}>
                  <tr key={n.UnitSettingId} data-unittest="unitSettingsData">
                    <td width="40%">
                      {n.Name} <img src={help} alt="icon" className={s.icon} data-tip={tooltip}></img>
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        name={'uval_' + n.UnitSettingId}
                        onChange={e => changeUnitSetting({ id: n.UnitSettingId, value: e.target.value })}
                        value={n.Value}
                        className={`${s.formControl} ${
                          localformErrors && localformErrors['uval_' + n.UnitSettingId] ? s.formControlError : ''
                        }`}
                      />
                      {localformErrors && localformErrors['uval_' + n.UnitSettingId] && (
                        <p role="alert" className={s.error}>
                          {localformErrors['uval_' + n.UnitSettingId]}
                        </p>
                      )}
                    </td>
                    <th className={s.range}>
                      {n.MinimumValue?.toString() ? '(Min ' + n.MinimumValue + ', ' : ''}{' '}
                      {n.MaximumValue?.toString() ? ' Max ' + n.MaximumValue + ')' : ''}
                    </th>
                    <td>{n.UnitOfMeasure}</td>
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </Form>
      <ReactTooltip />
    </div>
  );
};

UnitSettingsListTable.defaultProps = {
  unitSettingsData: []
};

UnitSettingsListTable.propTypes = {
  changeUnitSetting: PropTypes.func.isRequired,
  unitSettingsData: PropTypes.array,
  UpdateAllowed: PropTypes.bool
};

export default injectIntl(UnitSettingsListTable);
