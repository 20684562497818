import moment from 'moment';

export const APP_VERSION = 'alpha2';
//UI
export const IS_LOADING = 'IS_LOADING';
export const REFRESH_TIME = 3000; //10 seconds
//export const REFRESH_TIME = 120000; //120 seconds
export const REFRESH_TIME_OTHER = 60000; //60 seconds
export const REFRESH_NOTIFICATION = 120000; //60 seconds
export const DEFAULT_PAGE_SIZE = 20;

//domain name to set cookies
export const DOMAIN_NAME =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? 'localhost' : process.env.REACT_APP_DOMAIN_URL;
export const IS_LOCALHOST = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? true : false;

export const API = 'API';

//USERS
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_FORGOT_PASSWORD_STATUS = 'LOGIN_FORGOT_PASSWORD_STATUS';
export const USER_DETAILS = 'USER_DETAILS';
export const LOGOUT = 'LOGOUT';
export const MFA_VERIFICATION_ERROR = 'MFA_VERIFICATION_ERROR';

//User Management -
export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_ALL_USER_DETAILS = 'GET_ALL_USER_DETAILS';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_MANAGEMENT_ERROR = 'USER_MANAGEMENT_ERROR';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const UPDATE_USER_ATTRIBUTES = 'UPDATE_USER_ATTRIBUTES';
export const USER_UI_DETAILS = 'USER_UI_DETAILS';
export const SET_USER_CURRENT_PAGE = 'SET_USER_CURRENT_PAGE';
export const SET_USER_PAGE_FILTER = 'SET_USER_PAGE_FILTER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const UPDATE_USERPROFILE_SAVE_STATUS = 'UPDATE_USERPROFILE_SAVE_STATUS';
export const UPDATE_CHANGEPASSWORD_STATUS = 'UPDATE_CHANGEPASSWORD_STATUS';
export const USERDETAILS_CLEANUP = 'USERDETAILS_CLEANUP';
export const SET_RESET_USER_PASSWORD_STATUS = 'SET_RESET_USER_PASSWORD_STATUS';

//Devices
export const DEVICE_ERROR = 'DEVICE_ERROR';
export const SET_BUTTON_STATE = 'SET_BUTTON_STATE';
export const SET_DEVICES = 'SET_DEVICES';
export const SET_SELECTED_DEVICE = 'SET_SELECTED_DEVICE';
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';
export const UPDATE_COMMAND_STATUS = 'UPDATE_COMMAND_STATUS';
export const RESTORE_CHART_CONFIG = 'RESTORE_CHART_CONFIG';
export const SET_DEVICE_SEQUENCE = 'SET_DEVICE_SEQUENCE';
export const SET_DEVICE_TIMEZONE = 'SET_DEVICE_TIMEZONE';
export const GET_DEVICES_STATUS = 'GET_DEVICES_STATUS';
export const DISABLE_COMMANDS = 'DISABLE_COMMANDS';
export const DEVICE_LOADING = 'DEVICE_LOADING';
export const COMMAND_MODE_MANUAL = 'manual';
export const COMMAND_MODE_AUTO = 'auto';
export const COMMAND_MODE_SYSTEM = 'system';

//Devices
export const SET_THRESHOLDS = 'SET_THRESHOLDS';
export const SNAPSHOT_THRESHOLD = 'SNAPSHOT_THRESHOLD';
export const CANCEL_THRESHOLD_EDIT = 'CANCEL_THRESHOLD_EDIT';
export const SET_THRESHOLD_LOADING = 'UPDATE_THRESHOLD_LOADING';
export const UPDATE_THRESHOLD_STATUS = 'UPDATE_THRESHOLD_STATUS';
export const UPDATE_GLOBAL_THRESHOLD_VALUE = 'UPDATE_GLOBAL_THRESHOLD_VALUE';
export const UPDATE_SENSOR_THRESHOLD_VALUE = 'UPDATE_SENSOR_THRESHOLD_VALUE';

//Senors
export const UPDATE_SENSOR_DATA = 'UPDATE_SENSOR_DATA';
export const SENSOR_LOADING_DATA = 'SENSOR_LOADING_DATA';

//Charts
export const ADD_CHART = 'ADD_CHART';
export const UPDATE_CHART = 'UPDATE_CHART';
export const UPDATE_METRIC_DATA = 'UPDATE_METRIC_DATA';
export const CHART_ERROR = 'CHART_ERROR';
export const CHART_IS_LOADING = 'CHART_IS_LOADING';
export const REMOVE_CHART = 'REMOVE_CHART';
export const UPDATE_CHART_FILTERS = 'UPDATE_CHART_FILTERS';

//Events
export const EVENTS_ERROR = 'EVENTS_ERROR';
export const SET_EVENTS = 'SET_EVENTS';
export const UPDATE_EVENT_INPUT = 'UPDATE_EVENT_INPUT';
export const EVENT_IS_LOADING = 'EVENT_IS_LOADING';

//Event Actions
export const EVENT_ACTION_EXISTANCE_IS_LOADING = 'EVENT_ACTION_EXISTANCE_IS_LOADING';
export const EVENT_ACTION_EXISTANCE_ERROR = 'EVENT_ACTION_EXISTANCE_IS_LOADING';
export const EVENT_ACTION_EXISTANCE_STATUS = 'EVENT_ACTION_EXISTANCE_STATUS';

export const EVENT_ACTION_IS_LOADING = 'EVENT_ACTION_IS_LOADING';
export const EVENT_ACTION_ERROR = 'EVENT_ACTION_ERROR';
export const EVENT_ACTION_SET = 'EVENT_ACTION_SET';

export const EVENT_ACTIVITY_SAVE_ERROR = 'EVENT_ACTIVITY_SAVE_ERROR';
export const EVENT_ACTIVITY_SAVE = 'EVENT_ACTIVITY_SAVE';
export const EVENT_ACTIVITY_IS_SAVING = 'EVENT_ACTIVITY_IS_SAVING';
export const EVENT_ACTIVITY_NEW_SET = 'EVENT_ACTIVITY_NEW_SET';
export const EVENT_ACTION_SET_FOR_UNIT = 'EVENT_ACTION_SET_FOR_UNIT';
export const EVENT_ACTION_UPDATE_CHART = 'EVENT_ACTION_UPDATE_CHART';
export const EVENT_ACTION_UPDATE_CHART_FILTERS = 'EVENT_ACTION_UPDATE_CHART_FILTERS';
export const EVENT_ACTION_CHART_IS_LOADING = 'EVENT_ACTION_CHART_IS_LOADING';
export const EVENT_ACTION_UPDATE_METRIC_DATA = 'EVENT_ACTION_UPDATE_METRIC_DATA';
export const EVENT_ACTION_CHART_ERROR = 'EVENT_ACTION_CHART_ERROR';
export const EVENT_ACTION_CLEANUP = 'EVENT_ACTION_CLEANUP';
export const EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD = 'EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD';
export const EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD_SUCCESS = 'EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD_SUCCESS';

export const LINE_COLOURS = [
  '#0081b3',
  '#5B995A',
  '#D9196C',
  '#FFB300',
  '#3D4A74',
  '#3BABFD',
  '#96D65E',
  '#3A506B',
  '#8D5524',
  '#F95F41',
  '#EFB0C9',
  '#8746F1',
  '#D03A67'
];

//Language Selectors
export const LANGUAGE_TYPES = {
  en: { name: 'English' },
  de: { name: 'Deutsche' },
  fr: { name: 'Française' },
  es: { name: 'Español' },
  da: { name: 'Dansk' },
  baq: { name: 'Basque' }
};

//Navigation Links
// export const NAVIGATION_ITEMS = [
//   { name: "home", label: "Home", activeIcon: "home-blue", nonActiveIcon: require("../components/assets/home-grey.svg"), link: "/" },
//   { name: "units", label: "Units", activeIcon: "units-blue", nonActiveIcon: require("../components/assets/units-grey.svg"), link: "/"},
//   { name: "tasks", label: "Tasks", activeIcon: "tasks-blue", nonActiveIcon: require("../components/assets/tasks-grey.svg"), link: "/" },
//   { name: "adminControls", label: "Admin Controls", activeIcon: "admin-blue", nonActiveIcon: require("../components/assets/admin-grey.svg"), link: "/"},
//   { name: "accountSettings", label: "Account Settings", activeIcon: "settings-blue", nonActiveIcon: require("../components/assets/settings-grey.svg"), link: "/Settings" },
//   { name: "help", label: "Help", activeIcon: "help-blue", nonActiveIcon: require("../components/assets/help-grey.svg"), link: "/" },
// ];

export const CHANGE_LOCALE_LANGUAGE_SUCCESS = 'CHANGE_LOCALE_LANGUAGE_SUCCESS';
export const SET_TIMEZONE = 'CHANGE_TIMEZONE_SUCCESS';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

//Table Key Naming Prefix

export const TABLEKEY_NAMES = {
  profile: 'PROF#',
  unit: 'UNIT#',
  user: 'USER#',
  userprofile: 'USER#PROF#',
  widget: 'WDGT#',
  metadata: '#METADATA#'
};

//Profile
export const ADD_PROFILE = 'ADD_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_ALL_PROFILE_DETAILS = 'GET_ALL_PROFILE_DETAILS';
export const PROFILE_DETAIL = 'PROFILE_DETAIL';
export const PROFILE_IS_LOADING = 'PROFILE_IS_LOADING';
export const PROFILE_ERROR = 'ERROR_PROFILE';
export const UPDATE_SAVE_STATUS = 'UPDATE_SAVE_STATUS';
export const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
export const PROFILE_BANNER = 'PROFILE_BANNER';
export const UPDATE_ERROR_STATUS = 'UPDATE_ERROR_STATUS';
export const SET_SELECTED_PROFILE_WIDGET = 'SET_SELECTED_PROFILE_WIDGET';

//Widgets
export const ADD_WIDGET = 'ADD_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const GET_ALL_WIDGET_DETAILS = 'GET_ALL_WIDGET_DETAILS';
export const WIDGET_DETAIL = 'WIDGET_DETAIL';
export const WIDGET_IS_SAVING = 'WIDGET_IS_SAVING';
export const WIDGET_ERROR = 'ERROR_WIDGET';
export const UPDATE_WIDGET_SAVE_STATUS = 'UPDATE_WIDGET_SAVE_STATUS';
export const WIDGET_IS_LOADING = 'WIDGET_IS_LOADING';

//User profile Widgets
export const GET_ALL_USER_PROFILE_WIDGET_DETAILS = 'GET_ALL_PROFILE_WIDGET_DETAILS';
export const GET_ALL_USER_PROFILE_WIDGET_ERROR = 'GET_ALL_USER_PROFILE_WIDGET_ERROR';
export const SET_USER_PROFILE_WIDGET_DETAILS = 'SET_USER_PROFILE_WIDGET_DETAILS';
export const GET_ALL_USER_PROFILE_UNITS = 'GET_ALL_USER_PROFILE_UNIT';
export const SET_USER_PROFILE_WIDGET_DETAILS_LOADING = 'SET_USER_PROFILE_WIDGET_DETAILS_LOADING';
export const SELECT_ALL_PAGES = 'Select All Pages';

//Units
export const GET_ALL_UNITS = 'GET_ALL_UNITS';
export const GET_ALL_UNITS_ERROR = 'GET_ALL_UNITS_ERROR';
export const UNIT_LOADING = 'UNIT_LOADING';
export const SET_UNITS_CURRENT_PAGE = 'SET_UNITS_CURRENT_PAGE';
export const SET_UNITS_PAGE_FILTER = 'SET_UNITS_PAGE_FILTER';
export const DESCRIBE_UNIT = 'DESCRIBE_UNIT';
export const SET_UNIT_SEARCH_RESULT = 'SET_UNIT_SEARCH_RESULT';
export const SET_UNIT_IOT_INFO = 'SET_UNIT_IOT_INFO';
export const RESET_UNIT_MESSAGE = 'RESET_UNIT_MESSAGE';
export const GET_ALL_UNIT_FILTERS = 'GET_ALL_UNIT_FILTERS';
export const SET_UNIT_FILTERED_RESULTS = 'SET_UNIT_FILTERED_RESULTS';
export const SET_UNIT_REGION_LIST = 'SET_UNIT_REGION_LIST';
export const SET_UNIT_MANAGER_ENG_LIST = 'SET_UNIT_MANAGER_ENG_LIST';
export const SET_UNIT_SETTINGS_LIST = 'SET_UNIT_SETTINGS_LIST';
export const UNIT_SETTINGS_CHANGE = 'UNIT_SETTINGS_CHANGE';
export const UNIT_SETTINGS_ERROR = 'UNIT_SETTINGS_ERROR';
export const UNIT_SETTINGS_LOADING = 'UNIT_SETTINGS_LOADING';
export const UNIT_SETTINGS_UPDATE_STATUS = 'UNIT_SETTINGS_UPDATE_STATUS';

//Organisation Management -
export const GET_ALL_ORGANISATION_DETAILS = 'GET_ALL_ORGANISATION_DETAILS';
export const ORGANISATION_IS_LOADING = 'ORGANISATION_IS_LOADING';
export const ORGANISATION_MANAGEMENT_ERROR = 'ORGANISATION_MANAGEMENT_ERROR';
export const UPDATE_ORGANISATION_ATTRIBUTES = 'UPDATE_ORGANISATION_ATTRIBUTES';
export const SET_ORGANISATION_DETAILS = 'SET_ORGANISATION_DETAILS';
export const UPDATE_ORGANISATION_USER_SAVE_STATUS = 'UPDATE_ORGANISATION_USER_SAVE_STATUS';
export const UPDATE_ORGANISATION_DETAILS = 'UPDATE_ORGANISATION_DETAILS';
export const SET_PARENT_ORGANISATION_DETAILS = 'SET_PARENT_ORGANISATION_DETAILS';
export const UPDATE_ORGANISATION_USERS = 'UPDATE_ORGANISATION_USERS';
export const UPDATE_ORGANISATION_UNITS = 'UPDATE_ORGANISATION_UNITS';
export const SET_ORGANISATION_CURRENT_PAGE = 'SET_ORGANISATION_CURRENT_PAGE';
export const SET_ORGANISATION_PAGE_FILTER = 'SET_ORGANISATION_PAGE_FILTER';

//Unit Model Version -
export const GET_ALL_MODEL_VERSION_DETAILS = 'GET_ALL_MODEL_VERSION_DETAILS';
export const MODEL_VERSION_IS_LOADING = 'MODEL_VERSION_IS_LOADING';
export const MODEL_VERSION_MANAGEMENT_ERROR = 'MODEL_VERSION_MANAGEMENT_ERROR';
export const UPDATE_MODEL_VERSION_ATTRIBUTES = 'UPDATE_MODEL_VERSION_ATTRIBUTES';
export const SET_MODEL_VERSION_DETAILS = 'SET_MODEL_VERSION_DETAILS';
export const UPDATE_MODEL_VERSION_USER_SAVE_STATUS = 'UPDATE_MODEL_VERSION_USER_SAVE_STATUS';
export const SET_MODEL_VERSION_DETAILS_DUPLICATE = 'SET_MODEL_VERSION_DETAILS_DUPLICATE';
export const SET_MODEL_VERSION_CHANGE = 'SET_MODEL_VERSION_CHANGE';

//Unit Model version Thresholds

export const MODEL_VERSION_THRESHOLDS_MANAGEMENT_ERROR = 'MODEL_VERSION_THRESHOLDS_MANAGEMENT_ERROR';
export const MODEL_VERSION_THRESHOLDS_IS_LOADING = 'MODEL_VERSION_THRESHOLDS_IS_LOADING';
export const GET_ALL_MODEL_VERSION_DETAILS_THRESHOLDS = 'GET_ALL_MODEL_VERSION_DETAILS_THRESHOLDS';
export const GET_ALL_MODEL_VERSION_DETAILS_SEQUENCES_VERSION = 'GET_ALL_MODEL_VERSION_DETAILS_SEQUENCES_VERSION';
export const SET_THRESHOLDS_PAGE_FILTER = 'SET_THRESHOLDS_PAGE_FILTER';
export const SET_MODEL_VERSION_THRESHOLDS_DETAILS = 'SET_MODEL_VERSION_THRESHOLD_DETAILS';
export const SET_MODEL_VERSION_THRESHOLDS_CHANGE = 'SET_MODEL_VERSION_THRESHOLD_CHANGE';
export const UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS = 'UPDATE_MODEL_VERSION_THRESHOLDS_SAVE_STATUS';
export const UPDATE_MODEL_VERSION_THRESHOLDS_SHOW_SCREEN = 'UPDATE_MODEL_VERSION_THRESHOLDS_SHOW_SCREEN';
export const SET_MODEL_VERSION_CURRENT_PAGE = 'SET_MODEL_VERSION_CURRENT_PAGE';
export const SET_MODEL_VERSION_PAGE_FILTER = 'SET_MODEL_VERSION_PAGE_FILTER';

//Unit Model version Global Settings
export const MODEL_VERSION_GLOBAL_SETTINGS_MANAGEMENT_ERROR = 'MODEL_VERSION_GLOBAL_SETTINGS_MANAGEMENT_ERROR';
export const MODEL_VERSION_GLOBAL_SETTINGS_IS_LOADING = 'MODEL_VERSION_GLOBAL_SETTINGS_IS_LOADING';
export const GET_ALL_MODEL_VERSION_GLOBAL_SETTINGS = 'GET_ALL_MODEL_VERSION_GLOBAL_SETTINGS';
export const UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS = 'UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SAVE_STATUS';
export const SET_MODEL_VERSION_GLOBAL_SETTINGS_DETAILS = 'SET_MODEL_VERSION_GLOBAL_SETTINGS_DETAILS';
export const SET_MODEL_VERSION_GLOBAL_SETTINGS_CHANGE = 'SET_MODEL_VERSION_GLOBAL_SETTINGS_CHANGE';
export const UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SHOW_SCREEN = 'UPDATE_MODEL_VERSION_GLOBAL_SETTINGS_SHOW_SCREEN';

export const GET_GLOBAL_SETTINGS = 'GET_GLOBAL_SETTINGS';
export const GLOBAL_SETTINGS_IS_LOADING = 'GLOBAL_SETTINGS_IS_LOADING';
export const GLOBAL_SETTINGS_MANAGEMENT_ERROR = 'GLOBAL_SETTINGS_MANAGEMENT_ERROR';

export const GLOBAL_SETTINGS_TYPE = 'GLOBAL_SETTINGS';
export const FLOWSEQUENCE_TYPE = 'FLOWSEQUENCE';
export const SERVICE_TYPE = 'SERVICE_TYPE';
export const GLOBAL_SETTINGS_SET_CURRENT_PAGE = 'GLOBAL_SETTINGS_SET_CURRENT_PAGE';
export const GLOBAL_SETTINGS_SET_PAGE_FILTER = 'GLOBAL_SETTINGS_SET_PAGE_FILTER';
export const GLOBAL_SETTINGS_SET = 'GLOBAL_SETTINGS_SET';
export const GLOBAL_SETTINGS_UPDATE_SAVE_STATUS = 'GLOBAL_SETTINGS_UPDATE_SAVE_STATUS';
export const GLOBAL_SETTINGS_SET_CHANGE = 'GLOBAL_SETTINGS_SET_CHANGE';
export const GLOBAL_SETTINGS_SET_FILTERED_RESULTS = 'GLOBAL_SETTINGS_SET_FILTERED_RESULTS';

// Model Component

export const GET_ALL_MODEL_COMPONENTS_POSITIONS_DETAILS = 'GET_ALL_MODEL_COMPONENTS_POSITIONS_DETAILS';
export const MODEL_COMPONENTS_POSITIONS_IS_LOADING = 'MODEL_COMPONENTS_POSITIONS_IS_LOADING';
export const MODEL_COMPONENTS_POSITIONS_ERROR = 'MODEL_COMPONENTS_POSITIONS_ERROR';

export const MODELCOMPONENTPOSITION_ERROR = 'MODELCOMPONENTPOSITION_ERROR';
export const GET_ALL_MODELCOMPONENTPOSITION_DETAILS = 'GET_ALL_MODELCOMPONENTPOSITION_DETAILS';
export const MODELCOMPONENTPOSITION_IS_LOADING = 'MODELCOMPONENTPOSITION_IS_LOADING';
export const SET_MODELCOMPONENTPOSITION_CURRENT_PAGE = 'SET_MODELCOMPONENTPOSITION_CURRENT_PAGE';
export const SET_MODELCOMPONENTPOSITION_PAGE_FILTER = 'SET_MODELCOMPONENTPOSITION_PAGE_FILTER';
export const SET_MODELCOMPONENTPOSITION_CHANGE = 'SET_MODELCOMPONENTPOSITION_CHANGE';
export const SET_MODELCOMPONENTPOSITION = 'SET_MODELCOMPONENTPOSITION';
export const UPDATE_MODELCOMPONENTPOSITION_SAVE_STATUS = 'UPDATE_MODELCOMPONENTPOSITION_SAVE_STATUS';
export const MODELCOMPONENTPOSITION_CLEANUP = ' MODELCOMPONENTPOSITION_CLEANUP';
export const SET_MODELCOMPONENTPOSITION_FILTERED_RESULTS = 'SET_MODELCOMPONENTPOSITION_FILTERED_RESULTS';

// Unit Components
export const GET_UNIT_COMPONENT_PARTS = 'GET_UNIT_COMPONENT_PARTS';
export const GET_UNIT_COMPONENT_PARTS_ERROR = 'GET_UNIT_COMPONENT_PARTS_ERROR';

// ALARMS

export const GET_ALL_ALARMS_DETAILS = 'GET_ALL_ALARMS_DETAILS';
export const ALARMS_IS_LOADING = 'ALARMS_IS_LOADING';
export const ALARMS_ERROR = 'ALARMS_ERROR';
export const GET_ALL_ALARMS_FILTERS = 'GET_ALL_ALARMS_FILTERS';
export const SET_ALARMS_CURRENT_PAGE = 'SET_ALARMS_CURRENT_PAGE';
export const SET_ALARMS_FILTERED_RESULTS = 'SET_ALARMS_FILTERED_RESULTS';
export const SET_ALARMS_PAGE_FILTER = 'SET_ALARMS_PAGE_FILTER';

// ALARM STATUS
export const ALARM_STATUS_NEW = 'NEW';
export const ALARM_STATUS_PENDING = 'PENDING';
export const ALARM_STATUS_RESOLVED = 'RESOLVED';

//EVENT ACTION ACTIVITY TYPE
export const EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS = {
  statusType: 'UPDATEDSTATUS',
  messageTranslationCode: 'eventActionActitivity.changedTheStatusTo'
};

export const EVENT_ACTION_ACTIVITY_TYPE_ASSIGN = {
  statusType: 'ASSIGN',
  messageTranslationCode: 'eventActionActitivity.changedTheAssigneeTo'
};

export const EVENT_ACTION_ACTIVITY_TYPE_COMMENT = {
  statusType: 'COMMENT',
  messageTranslationCode: 'eventActionActitivity.addedAComment'
};

export const EVENT_ACTION_ACTIVITY_TYPE_LINKALARM = {
  statusType: 'LINKALARM',
  messageTranslationCode: 'eventActionActitivity.linkedAlarm'
};

export const EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM = {
  statusType: 'UNLINKALARM',
  messageTranslationCode: 'eventActionActitivity.unlinkedAlarm'
};

export const GET_DEVICES_ALARMS_DETAILS = 'GET_DEVICES_ALARMS_DETAILS';

//Calibration Equation
export const SET_CALIBRATION_EQUATION = 'SET_CALIBRATION_EQUATION';
export const CALIBRATION_EQUATION_ERROR = 'CALIBRATION_EQUATION_ERROR';
export const GET_ALL_CALIBRATION_EQUATION_DETAILS = 'GET_ALL_CALIBRATION_EQUATION_DETAILS';
export const CALIBRATION_EQUATION_IS_LOADING = 'CALIBRATION_EQUATION_IS_LOADING';
export const SET_CALIBRATION_EQUATION_CURRENT_PAGE = 'SET_CALIBRATION_EQUATION_CURRENT_PAGE';
export const SET_CALIBRATION_EQUATION_PAGE_FILTER = 'SET_CALIBRATION_EQUATION_PAGE_FILTER';
export const SET_CALIBRATION_EQUATION_CHANGE = 'SET_CALIBRATION_EQUATION_CHANGE';
export const UPDATE_CALIBRATION_EQUATION_SAVE_STATUS = 'UPDATE_CALIBRATION_EQUATION_SAVE_STATUS';
export const SET_CALIBRATION_EQUATION_CONSTANT_CHANGE = 'SET_CALIBRATION_EQUATION_CONSTANT_CHANGE';
export const SHOW_CALIBRATION_EQUATION_CONSTANT_ADD_EDIT_SCREEN = 'SHOW_CALIBRATION_EQUATION_CONSTANT_ADD_EDIT_SCREEN';
export const SET_CALIBRATION_EQUATION_CONSTANT_LIST_CHANGE = 'SET_CALIBRATION_EQUATION_CONSTANT_LIST_CHANGE';
export const SET_CALIBRATION_EQUATION_CLEANUP = 'SET_CALIBRATION_EQUATION_CLEANUP';

//Task Templates:
export const TASK_TEMPLATE_ERROR = 'TASK_TEMPLATE_ERROR';
export const GET_ALL_TASK_TEMPLATE_DETAILS = 'GET_ALL_TASK_TEMPLATE_DETAILS';
export const TASK_TEMPLATE_IS_LOADING = 'TASK_TEMPLATE_IS_LOADING';
export const SET_TASK_TEMPLATE_CURRENT_PAGE = 'SET_TASK_TEMPLATE_CURRENT_PAGE';
export const SET_TASK_TEMPLATE_PAGE_FILTER = 'SET_TASK_TEMPLATE_PAGE_FILTER';
export const SET_TASK_TEMPLATE_CHANGE = 'SET_TASK_TEMPLATE_CHANGE';
export const SET_TASK_TEMPLATE = 'SET_TASK_TEMPLATE';
export const UPDATE_TASK_TEMPLATE_SAVE_STATUS = 'UPDATE_TASK_TEMPLATE_SAVE_STATUS';

//Checklist Templates:
export const CHECKLIST_TEMPLATE_ERROR = 'CHECKLIST_TEMPLATE_ERROR';
export const GET_ALL_CHECKLIST_TEMPLATE_DETAILS = 'GET_ALL_CHECKLIST_TEMPLATE_DETAILS';
export const CHECKLIST_TEMPLATE_IS_LOADING = 'CHECKLIST_TEMPLATE_IS_LOADING';
export const SET_CHECKLIST_TEMPLATE_CURRENT_PAGE = 'SET_CHECKLIST_TEMPLATE_CURRENT_PAGE';
export const SET_CHECKLIST_TEMPLATE_PAGE_FILTER = 'SET_CHECKLIST_TEMPLATE_PAGE_FILTER';
export const SET_CHECKLIST_TEMPLATE_CHANGE = 'SET_CHECKLIST_TEMPLATE_CHANGE';
export const SET_CHECKLIST_TEMPLATE = 'SET_CHECKLIST_TEMPLATE';
export const UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS = 'UPDATE_CHECKLIST_TEMPLATE_SAVE_STATUS';

//Checklist
export const CHECKLIST_ERROR = 'CHECKLIST_ERROR';
export const GET_ALL_CHECKLIST_DETAILS = 'GET_ALL_CHECKLIST_DETAILS';
export const CHECKLIST_IS_LOADING = 'CHECKLIST_IS_LOADING';
export const SET_CHECKLIST_CURRENT_PAGE = 'SET_CHECKLIST_CURRENT_PAGE';
export const SET_CHECKLIST_PAGE_FILTER = 'SET_CHECKLIST_PAGE_FILTER';
export const SET_CHECKLIST_CHANGE = 'SET_CHECKLIST_CHANGE';
export const SET_CHECKLIST = 'SET_CHECKLIST';
export const UPDATE_CHECKLIST_SAVE_STATUS = 'UPDATE_CHECKLIST_SAVE_STATUS';
export const SET_CHECKLIST_FILTERED_RESULTS = 'SET_CHECKLIST_FILTERED_RESULTS';
export const GET_ALL_CHECKLIST_FILTERS = 'GET_ALL_CHECKLIST_FILTERS';

//Tasks:
export const TASKS_ERROR = 'TASKS_ERROR';
export const GET_ALL_TASKS_DETAILS = 'GET_ALL_TASKS_DETAILS';
export const TASKS_IS_LOADING = 'TASKS_IS_LOADING';
export const SET_TASKS_CURRENT_PAGE = 'SET_TASKS_CURRENT_PAGE';
export const SET_TASKS_PAGE_FILTER = 'SET_TASKS_PAGE_FILTER';
export const SET_TASKS_CHANGE = 'SET_TASKS_CHANGE';
export const SET_TASK = 'SET_TASK';
export const UPDATE_TASKS_SAVE_STATUS = 'UPDATE_TASKS_SAVE_STATUS';
export const SET_TASKS_FILTERED_RESULTS = 'SET_TASKS_FILTERED_RESULTS';
export const RESET_TASKS = 'RESET_TASKS ';
export const SET_TASKS_COMMENT_CHANGE = 'SET_TASKS_COMMENT_CHANGE';
export const GET_ALL_TASKS_FILTERS = 'GET_ALL_TASKS_FILTERS';

// Task Status
export const TASK_STATUS_ACTIVE = 'Active';
export const TASK_STATUS_COMPLETE = 'Complete';

export const TASK_ASSIGNED_TO_ME = 'Assigned To Me';
export const TASK_CREATED_BY_ME = 'Created By Me';
export const TASK_MARK_AS_COMPLETE = 'Mark As Complete';

// Checklist Status
export const CHECKLIST_STATUS_ACTIVE = 'Active';
export const CHECKLIST_STATUS_COMPLETE = 'Complete';
export const CHECKLIST_ASSIGNED_TO_ME = 'Assigned To Me';
export const CHECKLIST_CREATED_BY_ME = 'Created By Me';

// Calibration
export const CALIBRATION_ERROR = 'CALIBRATION_ERROR';
export const GET_ALL_CALIBRATION_DETAILS = 'GET_ALL_CALIBRATION_DETAILS';
export const CALIBRATION_IS_LOADING = 'CALIBRATION_IS_LOADING';
export const SET_CALIBRATION_CURRENT_PAGE = 'SET_CALIBRATION_CURRENT_PAGE';
export const SET_CALIBRATION_PAGE_FILTER = 'SET_CALIBRATION_PAGE_FILTER';
export const SET_CALIBRATION_CHANGE = 'SET_CALIBRATION_CHANGE';
export const SET_CALIBRATION = 'SET_CALIBRATION';
export const UPDATE_CALIBRATION_SAVE_STATUS = 'UPDATE_CALIBRATION_SAVE_STATUS';
export const SET_CALIBRATION_RESULT_CHANGE = 'SET_CALIBRATION_RESULT_CHANGE';
export const SET_CALIBRATION_CONSTANT_CHANGE = 'SET_CALIBRATION_CONSTANT_CHANGE';
export const SET_CALIBRATION_COMMENT_CHANGE = 'SET_CALIBRATION_COMMENT_CHANGE';
export const CALIBRATION_VIEW_STATUS = 'CALIBRATION_VIEW_STATUS';
export const SET_CALIBRATION_CONST = 'SET_CALIBRATION_CONST';

// Checklist Status
export const CALIBRATION_DRAFT = 'Draft';
export const CALIBRATION_FINALIZED = 'Finalized';
export const CALIBRATION_UPLOADED = 'Uploaded';

//Event Codes

export const EVENTCODE_ERROR = 'EVENTCODE_ERROR';
export const GET_ALL_EVENTCODE_DETAILS = 'GET_ALL_EVENTCODE_DETAILS';
export const EVENTCODE_IS_LOADING = 'EVENTCODE_IS_LOADING';
export const SET_EVENTCODE_CURRENT_PAGE = 'SET_EVENTCODE_CURRENT_PAGE';
export const SET_EVENTCODE_PAGE_FILTER = 'SET_EVENTCODE_PAGE_FILTER';
export const SET_EVENTCODE_CHANGE = 'SET_EVENTCODE_CHANGE';
export const SET_EVENTCODE = 'SET_EVENTCODE';
export const UPDATE_EVENTCODE_SAVE_STATUS = 'UPDATE_EVENTCODE_SAVE_STATUS';
export const EVENTCODE_CLEANUP = 'EVENTCODE_CLEANUP';
export const SET_EVENT_CODE_FILTERED_RESULTS = 'SET_EVENT_CODE_FILTERED_RESULTS';

//Models
export const MODEL_IS_LOADING = 'MODEL_IS_LOADING';
export const UPDATE_MODEL_USER_SAVE_STATUS = 'UPDATE_MODEL_USER_SAVE_STATUS';
export const MODEL_MANAGEMENT_ERROR = 'MODEL_MANAGEMENT_ERROR';
export const SET_MODEL_CURRENT_PAGE = 'SET_MODEL_CURRENT_PAGE';
export const SET_MODEL_PAGE_FILTER = 'SET_MODEL_PAGE_FILTER';
export const SET_MODEL_DETAILS = 'SET_MODEL_DETAILS';
export const SET_MODEL_DETAILS_DUPLICATE = 'SET_MODEL_DETAILS_DUPLICATE';
export const SET_MODEL_CHANGE = 'SET_MODEL_CHANGE';
export const GET_ALL_MODEL_DETAILS = 'GET_ALL_MODEL_DETAILS';
export const SET_MODEL_FILTERED_RESULTS = 'SET_MODEL_FILTERED_RESULTS';
export const MODEL_CLEANUP = 'MODEL_CLEANUP';
export const COMPONENT_POSITION_MODEL_IS_LOADING = 'COMPONENT_POSITION_MODEL_IS_LOADING';
export const GET_ALL_COMPONENT_POSITION_MODEL = 'GET_ALL_COMPONENT_POSITION_MODEL';
export const COMPONENT_POSITION_MODEL_MANAGEMENT_ERROR = 'COMPONENT_POSITION_MODEL_MANAGEMENT_ERROR';
export const UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS = 'UPDATE_COMPONENT_POSITION_MODEL_SAVE_STATUS';
export const UPDATE_COMPONENT_POSITION_MODEL_SHOW_SCREEN = 'UPDATE_COMPONENT_POSITION_MODEL_SHOW_SCREEN';
export const SET_COMPONENT_POSITION_MODEL_CHANGE = 'SET_COMPONENT_POSITION_MODEL_CHANGE';
export const GET_ALL_MODELVERSION_MODEL = 'GET_ALL_MODELVERSION_MODEL';
export const SET_DUPLICATE_MODEL = 'SET_DUPLICATE_MODEL';
export const UPLOAD_UNIT_COMPONENT = 'UPLOAD_UNIT_COMPONENT';
export const UPLOAD_UNIT_COMPONENT_STATUS = 'UPLOAD_UNIT_COMPONENT_STATUS';

//Model Component Type
export const MODELCOMPONENTTYPE_ERROR = 'MODELCOMPONENTTYPE_ERROR';
export const GET_ALL_MODELCOMPONENTTYPE_DETAILS = 'GET_ALL_MODELCOMPONENTTYPE_DETAILS';
export const MODELCOMPONENTTYPE_IS_LOADING = 'MODELCOMPONENTTYPE_IS_LOADING';
export const SET_MODELCOMPONENTTYPE_CURRENT_PAGE = 'SET_MODELCOMPONENTTYPE_CURRENT_PAGE';
export const SET_MODELCOMPONENTTYPE_PAGE_FILTER = 'SET_MODELCOMPONENTTYPE_PAGE_FILTER';
export const SET_MODELCOMPONENTTYPE_CHANGE = 'SET_MODELCOMPONENTTYPE_CHANGE';
export const SET_MODELCOMPONENTTYPE = 'SET_MODELCOMPONENTTYPE';
export const UPDATE_MODELCOMPONENTTYPE_SAVE_STATUS = 'UPDATE_MODELCOMPONENTTYPE_SAVE_STATUS';
export const MODELCOMPONENTTYPE_CLEANUP = 'MODELCOMPONENTTYPE_CLEANUP';
export const SET_MODELCOMPONENTTYPE_FILTERED_RESULTS = 'SET_MODELCOMPONENTTYPE_FILTERED_RESULTS';

//Component Parts
export const GET_ALL_COMPONENT_PARTS_DETAILS = 'GET_ALL_COMPONENT_PARTS_DETAILS';
export const COMPONENT_PARTS_IS_LOADING = 'COMPONENT_PARTS_IS_LOADING';
export const COMPONENT_PARTS_ERROR = 'COMPONENT_PARTS_ERROR';
export const GET_ALL_COMPONENT_PARTS_FILTERS = 'GET_ALL_COMPONENT_PARTS_FILTERS';
export const SET_COMPONENT_PARTS_CURRENT_PAGE = 'SET_COMPONENT_PARTS_CURRENT_PAGE';
export const SET_COMPONENT_PARTS_FILTERED_RESULTS = 'SET_COMPONENT_PARTS_FILTERED_RESULTS';
export const SET_COMPONENT_PARTS_PAGE_FILTER = 'SET_COMPONENT_PARTS_PAGE_FILTER';
export const SET_COMPONENT_PARTS_CHANGE = 'SET_COMPONENT_PARTS_CHANGE';
export const SET_COMPONENT_PARTS = 'SET_COMPONENT_PARTS';
export const UPDATE_COMPONENT_PARTS_SAVE_STATUS = 'UPDATE_COMPONENT_PARTS_SAVE_STATUS';

//Approved Component
export const APPROVEDCOMPONENT_ERROR = 'APPROVEDCOMPONENT_ERROR';
export const GET_ALL_APPROVEDCOMPONENT_DETAILS = 'GET_ALL_APPROVEDCOMPONENT_DETAILS';
export const APPROVEDCOMPONENT_IS_LOADING = 'APPROVEDCOMPONENT_IS_LOADING';
export const SET_APPROVEDCOMPONENT_CURRENT_PAGE = 'SET_APPROVEDCOMPONENT_CURRENT_PAGE';
export const SET_APPROVEDCOMPONENT_PAGE_FILTER = 'SET_APPROVEDCOMPONENT_PAGE_FILTER';
export const SET_APPROVEDCOMPONENT_CHANGE = 'SET_APPROVEDCOMPONENT_CHANGE';
export const SET_APPROVEDCOMPONENT = 'SET_APPROVEDCOMPONENT';
export const UPDATE_APPROVEDCOMPONENT_SAVE_STATUS = 'UPDATE_APPROVEDCOMPONENT_SAVE_STATUS';
export const APPROVEDCOMPONENT_CLEANUP = 'APPROVEDCOMPONENT_CLEANUP';
export const SET_APPROVEDCOMPONENT_FILTERED_RESULTS = 'SET_APPROVEDCOMPONENT_FILTERED_RESULTS';
export const SHOW_APPROVEDCOMPONENT_SUB_COMPONENT_ADD_EDIT_SCREEN = 'SHOW_APPROVEDCOMPONENT_SUB_COMPONENT_ADD_EDIT_SCREEN';
export const SET_APPROVED_SUB_COMPONENT_CHANGE = 'SET_APPROVED_SUB_COMPONENT_CHANGE';
export const SET_APPROVED_SUB_COMPONENT_LIST = 'SET_APPROVED_SUB_COMPONENT_LIST';

//Unit Configuration
export const UNIT_CONFIGURATION_MANAGEMENT_ERROR = 'UNIT_CONFIGURATION_MANAGEMENT_ERROR';
export const UNIT_CONFIGURATION_GET_COMPONENT_PARTS = 'UNIT_CONFIGURATION_GET_COMPONENT_PARTS';
export const UNIT_CONFIGURATION_COMPONENT_PARTS_IS_LOADING = 'UNIT_CONFIGURATION_COMPONENT_PARTS_IS_LOADING';
export const UPDATE_COMPONENT_PARTS_SHOW_SCREEN = 'UPDATE_COMPONENT_PARTS_SHOW_SCREEN';
export const UNIT_CONFIGURATION_SET_COMPONENT_PART_CHANGE = 'UNIT_CONFIGURATION_SET_COMPONENT_PART_CHANGE';
export const UNIT_CONFIGURATION_GET_SPARE_COMPONENT_PARTS = 'UNIT_CONFIGURATION_GET_SPARE_COMPONENT_PARTS';
export const UNIT_CONFIGURATION_UPDATE_COMPONENT_PARTS_SAVE_STATUS = 'UNIT_CONFIGURATION_UPDATE_COMPONENT_PARTS_SAVE_STATUS';

//Model Version Units
export const MODEL_VERSION_UNITS_MANAGEMENT_ERROR = 'MODEL_VERSION_UNITS_MANAGEMENT_ERROR';
export const MODEL_VERSION_UNITS_IS_LOADING = 'MODEL_VERSION_UNITS_IS_LOADING';
export const UPDATE_MODEL_VERSION_UNITS_SAVE_STATUS = 'UPDATE_MODEL_VERSION_UNITS_SAVE_STATUS';
export const GET_ALL_MODEL_VERSION_UNITS = 'GET_ALL_MODEL_VERSION_UNITS';
export const SET_MODEL_VERSION_UNITS_CHANGE = 'SET_MODEL_VERSION_UNITS_CHANGE';
export const UPDATE_MODEL_VERSION_UNITS_SHOW_SCREEN = 'UPDATE_MODEL_VERSION_UNITS_SHOW_SCREEN';

//Modelversion Model component positions
export const MODEL_VERSION_MODEL_COMPONENTS_POSITIONS_MODEL = 'MODEL_VERSION_MODEL_COMPONENTS_POSITIONS_MODEL';

//Environment Names
export const DEV_ENV = 'Development';
export const TEST_ENV = 'Testing';
export const STAGING_ENV = 'Staging';
export const PRODUCTION_ENV = 'Production';
export const ELEIOT_ENV = 'Eleiot';
export const DEMO_ENV = 'Demo';

//Unit Tagout

export const SET_UNIT_TAGOUT = 'SET_UNIT_TAGOUT';
export const GET_UNIT_TAGOUT = 'GET_UNIT_TAGOUT';
//Telemetry Links
export const GET_ALL_TELEMETRY_LINKS = 'GET_ALL_TELEMETRY_LINKS';
export const TELEMETRY_LINKS_IS_LOADING = 'TELEMETRY_LINKS_IS_LOADING';
export const TELEMETRY_LINKS_MANAGEMENT_ERROR = 'TELEMETRY_LINKS_MANAGEMENT_ERROR';
export const UPDATE_TELEMETRY_LINKS_SAVE_STATUS = 'UPDATE_TELEMETRY_LINKS_SAVE_STATUS';
export const UPDATE_TELEMETRY_LINKS_SHOW_SCREEN = 'UPDATE_TELEMETRY_LINKS_SHOW_SCREEN';
export const SET_TELEMETRY_LINKS_CHANGE = 'SET_TELEMETRY_LINKS_CHANGE';
export const SET_TELEMETRY_LINK = 'SET_TELEMETRY_LINK';

//Telemetry Types
export const TELEMETRY_TYPES = {
  0: { name: 'Cycles' },
  1: { name: 'Hours' },
  2: { name: 'Volume' }
};

export const GET_ALL_UNIT_TAGOUT_HISTORY_DETAILS = 'GET_ALL_UNIT_TAGOUT_HISTORY_DETAILS';
export const UNIT_TAGOUT_HISTORY_IS_LOADING = 'UNIT_TAGOUT_HISTORY_IS_LOADING';
export const UNIT_TAGOUT_HISTORY_ERROR = 'UNIT_TAGOUT_HISTORY_ERROR';
export const GET_ALL_UNIT_TAGOUT_HISTORY_FILTERS = 'GET_ALL_UNIT_TAGOUT_HISTORY_FILTERS';
export const SET_UNIT_TAGOUT_HISTORY_FILTERED_RESULTS = 'SET_UNIT_TAGOUT_HISTORY_FILTERED_RESULTS';
export const SET_UNIT_TAGOUT_HISTORY_CURRENT_PAGE = 'SET_UNIT_TAGOUT_HISTORY_CURRENT_PAGE';
export const SET_UNIT_TAGOUT_HISTORY_PAGE_FILTER = 'SET_UNIT_TAGOUT_HISTORY_PAGE_FILTER';

//Forgot Password
export const RESET_VERIFICATION_CODE = 'RESET_VERIFICATION_CODE';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_COMPLETE = 'FORGOT_PASSWORD_COMPLETE';
export const SET_FORGOT_PASSWORD_CONFIRMATION_SENT = 'SET_FORGOT_PASSWORD_CONFIRMATION_SENT';

// Command Scheduler
export const COMMAND_SCHEDULER_ERROR = 'COMMAND_SCHEDULER_ERROR';
export const GET_ALL_COMMAND_SCHEDULER_DETAILS = 'GET_ALL_COMMAND_SCHEDULER_DETAILS';
export const COMMAND_SCHEDULER_IS_LOADING = 'COMMAND_SCHEDULER_IS_LOADING';
export const SET_COMMAND_SCHEDULER_CURRENT_PAGE = 'SET_COMMAND_SCHEDULER_CURRENT_PAGE';
export const SET_COMMAND_SCHEDULER_PAGE_FILTER = 'SET_COMMAND_SCHEDULER_PAGE_FILTER';
export const SET_COMMAND_SCHEDULER_CHANGE = 'SET_COMMAND_SCHEDULER_CHANGE';
export const SET_COMMAND_SCHEDULER = 'SET_COMMAND_SCHEDULER';
export const UPDATE_COMMAND_SCHEDULER_SAVE_STATUS = 'UPDATE_COMMAND_SCHEDULER_SAVE_STATUS';
export const UPDATE_COMMAND_SCHEDULER_SAVE_STATUS_DATA = 'UPDATE_COMMAND_SCHEDULER_SAVE_STATUS_DATA';

//Schedule Frequency
export const SCHEDULE_FREQUENCY = {
  0: { name: 'Weekly' },
  1: { name: 'Daily' }
};

export const SCHEDULE_DURATION_IN_WEEKS = 12;
export const MINIMUM_START_TIME_IN_MINUTES = 5;

//UnitServices
export const UNITSERVICE_ERROR = 'UNITSERVICE_ERROR';
export const GET_ALL_UNITSERVICE_DETAILS = 'GET_ALL_UNITSERVICE_DETAILS';
export const UNITSERVICE_IS_LOADING = 'UNITSERVICE_IS_LOADING';
export const SET_UNITSERVICE_CURRENT_PAGE = 'SET_UNITSERVICE_CURRENT_PAGE';
export const SET_UNITSERVICE_PAGE_FILTER = 'SET_UNITSERVICE_PAGE_FILTER';
export const SET_UNITSERVICE_CHANGE = 'SET_UNITSERVICE_CHANGE';
export const SET_UNITSERVICE = 'SET_UNITSERVICE';
export const UPDATE_UNITSERVICE_SAVE_STATUS = 'UPDATE_UNITSERVICE_SAVE_STATUS';
export const UNIT_SERVICING_COMPONENT_PARTS = 'UNIT_SERVICING_COMPONENT_PARTS';
export const SET_UNITSERVICE_FILTERED_RESULTS = 'SET_UNITSERVICE_FILTERED_RESULTS';
export const SET_UNITSERVICE_FILTERS = 'SET_UNITSERVICE_FILTERS';
export const UNITSERVICE_CLEANUP = 'UNITSERVICE_CLEANUP';
export const START_UNITSERVICE_EXPORT = 'START_UNITSERVICE_EXPORT';
export const SET_UNITSERVICE_EXPORT_DATA = 'SET_UNITSERVICE_EXPORT_DATA';
export const SET_COMPUTE_UNITSERVICE_EXPORT = 'SET_COMPUTE_UNITSERVICE_EXPORT';

//Unit Service status
export const UNIT_SERVICE_STATUS = {
  0: { name: 'SCHEDULED' },
  1: { name: 'PENDING' },
  2: { name: 'COMPLETED' }
};

//Unit Statistics
export const UNITSTATISTICS_ERROR = 'UNITSTATISTICS_ERROR';
export const SET_UNITSTATISTICS_SETTINGS = 'SET_UNITSTATISTICS_SETTINGS';
export const UNITSTATISTICS_IS_LOADING = 'UNITSTATISTICS_IS_LOADING';
export const SET_UNITSTATISTICS_CHANGE = 'SET_UNITSTATISTICS_CHANGE';
export const SET_UNITSTATISTICS = 'SET_UNITSTATISTICS';
export const UPDATE_UNITSTATISTICS_SAVE_STATUS = 'UPDATE_UNITSTATISTICS_SAVE_STATUS';
export const UNITSTATISTICS_CLEANUP = 'UNITSTATISTICS_CLEANUP';

//daily summary export
export const DAILYSUMMART_DATA_ERROR = 'DAILYSUMMART_DATA_ERROR';
export const DAILYSUMMART_DATA_IS_LOADING = 'DAILYSUMMART_DATA_IS_LOADING';
export const SET_DAILYSUMMART_DATA_CHANGE = 'SET_DAILYSUMMART_DATA_CHANGE';
export const START_DAILYSUMMART_DATA_EXPORT = 'START_DAILYSUMMART_DATA_EXPORT';
export const SET_DAILYSUMMART_EXPORT_DATA = 'SET_DAILYSUMMART_EXPORT_DATA';
export const SET_COMPUTE_DAILYSUMMART_DATA_EXPORT = 'SET_COMPUTE_DAILYSUMMART_DATA_EXPORT';
export const SUMMARY_TYPES = {
  0: { name: 'Cycles', value: 'cycles' },
  1: { name: 'Min', value: 'min' },
  2: { name: 'Max', value: 'max' },
  3: { name: 'Volume processed', value: 'volume' },
  4: { name: 'Run hours', value: 'hours' },
  5: { name: 'Custom Summary', value: 'customsummary' }
};

export const STATISTIC_PERIODS = [
  {
    PeriodId: 1,
    Name: 'Last 24 hours',
    NameTranslationCode: 'unitStatistics.last24Hours',
    DashboardView: true,
    UnitView: true,
    toDate: () => moment().valueOf(),
    fromDate: () =>
      moment()
        .subtract(24, 'hours')
        .valueOf()
  },
  {
    PeriodId: 2,
    Name: 'Yesterday',
    NameTranslationCode: 'unitStatistics.yesterday',
    DashboardView: true,
    UnitView: true,
    toDate: () =>
      moment()
        .subtract(1, 'days')
        .endOf('day')
        .valueOf(),
    fromDate: () =>
      moment()
        .subtract(1, 'days')
        .startOf('day')
        .valueOf()
  },
  {
    PeriodId: 3,
    Name: 'Week to date',
    NameTranslationCode: 'unitStatistics.weekToDate',
    DashboardView: true,
    UnitView: true,
    toDate: () => moment().valueOf(),
    fromDate: () =>
      moment()
        .startOf('week')
        .valueOf()
  },
  {
    PeriodId: 4,
    Name: 'Month to date',
    NameTranslationCode: 'unitStatistics.monthToDate',
    DashboardView: false,
    UnitView: true,
    toDate: () => moment().valueOf(),
    fromDate: () =>
      moment()
        .startOf('month')
        .valueOf()
  },
  {
    PeriodId: 5,
    Name: 'Year to date',
    NameTranslationCode: 'unitStatistics.yearToDate',
    DashboardView: false,
    UnitView: true,
    toDate: () => moment().valueOf(),
    fromDate: () =>
      moment()
        .startOf('year')
        .valueOf()
  }
];

export const SELECTED_STAT_PERIOD_DASHBOARD = 'selectedstatisticsperioddashboard';
export const SELECTED_STAT_PERIOD_UNIT = 'selectedstatisticsperiodunit';

// Telemetry Parts listing
export const TELEMETRYPARTS_ERROR = 'TELEMETRYPARTS_ERROR';
export const GET_ALL_TELEMETRYPARTS_DETAILS = 'GET_ALL_TELEMETRYPARTS_DETAILS';
export const TELEMETRYPARTS_IS_LOADING = 'TELEMETRYPARTS_IS_LOADING';
export const SET_TELEMETRYPARTS_CURRENT_PAGE = 'SET_TELEMETRYPARTS_CURRENT_PAGE';
export const SET_TELEMETRYPARTS_PAGE_FILTER = 'SET_TELEMETRYPARTS_PAGE_FILTER';
export const SET_TELEMETRYPARTS_FILTERED_RESULTS = 'SET_TELEMETRYPARTS_FILTERED_RESULTS';
export const SET_TELEMETRYPARTS_FILTERS = 'SET_TELEMETRYPARTS_FILTERS';
export const TELEMETRYPARTS_CLEANUP = 'TELEMETRYPARTS_CLEANUP';
export const START_TELEMETRYPARTS_EXPORT = 'START_TELEMETRYPARTS_EXPORT';
export const SET_TELEMETRYPARTS_EXPORT_DATA = 'SET_TELEMETRYPARTS_EXPORT_DATA';
export const SET_COMPUTE_TELEMETRYPARTS_EXPORT = 'SET_COMPUTE_TELEMETRYPARTS_EXPORT';

//Notification delivery types
export const NOTIFICATION_DELIVERY_TYPES = {
  0: { name: 'Email', value: 'Email' },
  1: { name: 'SMS', value: 'SMS' }
};

//Notifications
export const NOTIFICATIONS_ERROR = 'NOTIFICATIONS_ERROR';
export const SET_NOTIFICATION_TYPE_USERS = 'SET_NOTIFICATION_TYPE_USERS';
export const NOTIFICATIONS_IS_LOADING = 'NOTIFICATIONS_IS_LOADING';
export const SET_NOTIFICATIONS_TYPE_USERS_CHANGE = 'SET_NOTIFICATIONS_TYPE_USERS_CHANGE';
export const SET_NOTIFICATION_TYPES = 'SET_NOTIFICATION_TYPES';
export const UPDATE_NOTIFICATION_TYPE_USERS_SAVE_STATUS = 'UPDATE_NOTIFICATION_TYPE_USERS_SAVE_STATUS';
export const NOTIFICATIONS_CLEANUP = 'NOTIFICATIONS_CLEANUP';
export const SET_NOTIFICATION_HISTORY = 'SET_NOTIFICATION_HISTORY';
export const SET_NOTIFICATIONS_FOR_BOARD = 'SET_NOTIFICATIONS_FOR_BOARD';
export const RESET_NOTIFICATION_ALERT = 'RESET_NOTIFICATION_ALERT';
export const SET_NOTIFICATION_HISTORY_CURRENT_PAGE = 'SET_NOTIFICATION_HISTORY_CURRENT_PAGE';

//FAQ Categories
export const FAQ_CATEGORY_ERROR = 'FAQ_CATEGORY_ERROR';
export const GET_ALL_FAQ_CATEGORY_DETAILS = 'GET_ALL_FAQ_CATEGORY_DETAILS';
export const FAQ_CATEGORY_IS_LOADING = 'FAQ_CATEGORY_IS_LOADING';
export const SET_FAQ_CATEGORY_CURRENT_PAGE = 'SET_FAQ_CATEGORY_CURRENT_PAGE';
export const SET_FAQ_CATEGORY_PAGE_FILTER = 'SET_FAQ_CATEGORY_PAGE_FILTER';
export const SET_FAQ_CATEGORY_CHANGE = 'SET_FAQ_CATEGORY_CHANGE';
export const SET_FAQ_CATEGORY = 'SET_FAQ_CATEGORY';
export const SET_FAQ_CATEGORY_CLEANUP = 'SET_FAQ_CATEGORY_CLEANUP';
export const UPDATE_FAQ_CATEGORY_SAVE_STATUS = 'UPDATE_FAQ_CATEGORY_SAVE_STATUS';

//FAQs
export const FAQ_ERROR = 'FAQ_ERROR';
export const GET_ALL_FAQ_DETAILS = 'GET_ALL_FAQ_DETAILS';
export const FAQ_IS_LOADING = 'FAQ_IS_LOADING';
export const SET_FAQ_CURRENT_PAGE = 'SET_FAQ_CURRENT_PAGE';
export const SET_FAQ_PAGE_FILTER = 'SET_FAQ_PAGE_FILTER';
export const SET_FAQ_CHANGE = 'SET_FAQ_CHANGE';
export const SET_FAQ = 'SET_FAQ';
export const SET_FAQS = 'SET_FAQS';
export const SET_FAQ_CLEANUP = 'SET_FAQ_CLEANUP';
export const UPDATE_FAQ_SAVE_STATUS = 'UPDATE_FAQ_SAVE_STATUS';

//Organisation Regions
export const ORGANISATION_REGION_ERROR = 'ORGANISATION_REGION_ERROR';
export const GET_ALL_ORGANISATION_REGION_DETAILS = 'GET_ALL_ORGANISATION_REGION_DETAILS';
export const ORGANISATION_REGION_IS_LOADING = 'ORGANISATION_REGION_IS_LOADING';
export const SET_ORGANISATION_REGION_CURRENT_PAGE = 'SET_ORGANISATION_REGION_CURRENT_PAGE';
export const SET_ORGANISATION_REGION_PAGE_FILTER = 'SET_ORGANISATION_REGION_PAGE_FILTER';
export const SET_ORGANISATION_REGION_CHANGE = 'SET_ORGANISATION_REGION_CHANGE';
export const SET_ORGANISATION_REGION = 'SET_ORGANISATION_REGION';
export const SET_ORGANISATION_REGION_CLEANUP = 'SET_ORGANISATION_REGION_CLEANUP';
export const UPDATE_ORGANISATION_REGION_SAVE_STATUS = 'UPDATE_ORGANISATION_REGION_SAVE_STATUS';

//Model version service plan
export const MODEL_VERSION_SERVICE_PLANS_MANAGEMENT_ERROR = 'MODEL_VERSION_SERVICE_PLANS_MANAGEMENT_ERROR';
export const MODEL_VERSION_SERVICE_PLANS_IS_LOADING = 'MODEL_VERSION_SERVICE_PLANS_IS_LOADING';
export const GET_ALL_MODEL_VERSION_SERVICE_PLANS = 'GET_ALL_MODEL_VERSION_SERVICE_PLANS';
export const SET_MODEL_VERSION_SERVICE_PLANS_DETAILS = 'SET_MODEL_VERSION_SERVICE_PLANS_DETAILS';
export const SET_MODEL_VERSION_SERVICE_PLANS_CHANGE = 'SET_MODEL_VERSION_SERVICE_PLANS_CHANGE';
export const UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS = 'UPDATE_MODEL_VERSION_SERVICE_PLANS_SAVE_STATUS';
export const UPDATE_MODEL_VERSION_SERVICE_PLANS_SHOW_SCREEN = 'UPDATE_MODEL_VERSION_SERVICE_PLANS_SHOW_SCREEN';

// Model Firmware Version
export const GET_ALL_MODEL_FIRMWARE_VERSION_DETAILS = 'GET_ALL_MODEL_FIRMWARE_VERSION_DETAILS';
export const UPDATE_MODEL_FIRMWARE_VERSION_SAVE_STATUS = 'UPDATE_MODEL_FIRMWARE_VERSION_SAVE_STATUS';
export const MODEL_FIRMWARE_VERSION_IS_LOADING = 'MODEL_FIRMWARE_VERSION_IS_LOADING';
export const MODEL_FIRMWARE_VERSION_ERROR = 'MODEL_FIRMWARE_VERSION_ERROR';
export const SET_MODEL_FIRMWARE_VERSION_CURRENT_PAGE = 'SET_MODEL_FIRMWARE_VERSION_CURRENT_PAGE';
export const SET_MODEL_FIRMWARE_VERSION_DETAILS = 'SET_MODEL_FIRMWARE_VERSION_DETAILS';
export const SET_MODEL_FIRMWARE_VERSION_PAGE_FILTER = 'SET_MODEL_FIRMWARE_VERSION_PAGE_FILTER';
export const SET_MODEL_FIRMWARE_VERSION_CHANGE = 'SET_MODEL_FIRMWARE_VERSION_CHANGE';
export const SET_MODEL_FIRMWARE_VERSION_FILTERED_RESULTS = 'SET_MODEL_FIRMWARE_VERSION_FILTERED_RESULTS';
export const MODEL_FIRMWARE_VERSION_CLEANUP = 'MODEL_FIRMWARE_VERSION_CLEANUP';
export const SET_ALL_COMPONENT_POSITION_MODEL_FIRMWARE_VERSION = 'SET_ALL_COMPONENT_POSITION_MODEL_FIRMWARE_VERSION';
export const SET_MODEL_FIRMWARE_VERSION_UNITS_CURRENT_PAGE = 'SET_MODEL_FIRMWARE_VERSION_UNITS_CURRENT_PAGE';
export const SET_MODEL_FIRMWARE_VERSION_UNITS_PAGE_FILTER = 'SET_MODEL_FIRMWARE_VERSION_UNITS_PAGE_FILTER';
export const SET_MODEL_FIRMWARE_VERSION_UNITS_FILTERED_RESULTS = 'SET_MODEL_FIRMWARE_VERSION_UNITS_FILTERED_RESULTS';

//Release Notes
export const RELEASENOTES_ERROR = 'RELEASENOTES_ERROR';
export const GET_ALL_RELEASENOTES_DETAILS = 'GET_ALL_RELEASENOTES_DETAILS';
export const RELEASENOTES_IS_LOADING = 'RELEASENOTES_IS_LOADING';
export const SET_RELEASENOTES_CURRENT_PAGE = 'SET_RELEASENOTES_CURRENT_PAGE';
export const SET_RELEASENOTES_PAGE_FILTER = 'SET_RELEASENOTES_PAGE_FILTER';
export const SET_RELEASENOTES_CHANGE = 'SET_RELEASENOTES_CHANGE';
export const SET_RELEASENOTES_CLEANUP = 'SET_RELEASENOTES_CLEANUP';
export const SET_RELEASENOTE = 'SET_RELEASENOTE';
export const SET_RELEASENOTES_VIEW = 'SET_RELEASENOTES_VIEW';
export const UPDATE_RELEASENOTES_SAVE_STATUS = 'UPDATE_RELEASENOTES_SAVE_STATUS';
export const SET_RELEASENOTES_FILTERED_RESULTS = 'SET_RELEASENOTES_FILTERED_RESULTS';

//Unit global settings
export const MODEL_VERSION_UNIT_GLOBAL_SETTINGS_MANAGEMENT_ERROR = 'MODEL_VERSION_UNIT_GLOBAL_SETTINGS_MANAGEMENT_ERROR';
export const MODEL_VERSION_UNIT_GLOBAL_SETTINGS_IS_LOADING = 'MODEL_VERSION_UNIT_GLOBAL_SETTINGS_IS_LOADING';
export const GET_ALL_MODEL_VERSION_UNIT_GLOBAL_SETTINGS = 'GET_ALL_MODEL_VERSION_UNIT_GLOBAL_SETTINGS';
export const SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_DETAILS = 'SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_DETAILS';
export const SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_CHANGE = 'SET_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_CHANGE';
export const UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS = 'UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SAVE_STATUS';
export const UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SHOW_SCREEN = 'UPDATE_MODEL_VERSION_UNIT_GLOBAL_SETTINGS_SHOW_SCREEN';

// unit add
export const UNIT_ADD_ERROR = 'UNIT_ADD_ERROR';
export const SET_UNIT_ADD_DROPDOWN_LIST = 'SET_UNIT_ADD_DROPDOWN_LIST';
export const UNIT_ADD_LOADING = 'UNIT_ADD_LOADING';
export const SET_UNIT_ADD_INFO = 'SET_UNIT_ADD_INFO';
export const UNIT_ADD_CHANGE = 'UNIT_ADD_CHANGE';
export const SET_UNIT_ADD_CLEANUP = 'SET_UNIT_ADD_CLEANUP';

//Sequence version
export const GET_ALL_SEQUENCEVERSION_DETAILS = 'GET_ALL_SEQUENCEVERSION_DETAILS';
export const SEQUENCEVERSION_IS_LOADING = 'SEQUENCEVERSION_IS_LOADING';
export const SEQUENCEVERSION_MANAGEMENT_ERROR = 'SEQUENCEVERSION_MANAGEMENT_ERROR';
export const SET_SEQUENCEVERSION_CURRENT_PAGE = 'SET_SEQUENCEVERSION_CURRENT_PAGE';
export const SET_SEQUENCEVERSION_DETAILS = 'SET_SEQUENCEVERSION_DETAILS';
export const SET_SEQUENCEVERSION_CHANGE = 'SET_SEQUENCEVERSION_CHANGE';
export const SET_SEQUENCEVERSION_FILTERED_RESULTS = 'SET_SEQUENCEVERSION_FILTERED_RESULTS';
export const SEQUENCEVERSION_CLEANUP = 'SEQUENCEVERSION_CLEANUP';
export const UPDATE_SEQUENCEVERSION_SAVE_STATUS = 'UPDATE_SEQUENCEVERSION_SAVE_STATUS';
export const SET_SEQUENCEVERSIONFILE_DETAILS = 'SET_SEQUENCEVERSIONFILE_DETAILS';
export const SET_SEQUENCEVERSIONFILE_CHANGE = 'SET_SEQUENCEVERSIONFILE_CHANGE';
export const UPDATE_SEQUENCEVERSIONFILE_SAVE_STATUS = 'UPDATE_SEQUENCEVERSIONFILE_SAVE_STATUS';

//static data
export const GET_ALL_STATICDATA_DETAILS = 'GET_ALL_STATICDATA_DETAILS';
export const STATICDATA_IS_LOADING = 'STATICDATA_IS_LOADING';
export const STATICDATA_MANAGEMENT_ERROR = 'STATICDATA_MANAGEMENT_ERROR';
export const SET_STATICDATA_CURRENT_PAGE = 'SET_STATICDATA_CURRENT_PAGE';
export const SET_STATICDATA_DETAILS = 'SET_STATICDATA_DETAILS';
export const SET_STATICDATA_CHANGE = 'SET_STATICDATA_CHANGE';
export const SET_STATICDATA_FILTERED_RESULTS = 'SET_STATICDATA_FILTERED_RESULTS';
export const STATICDATA_CLEANUP = 'STATICDATA_CLEANUP';
export const UPDATE_STATICDATA_SAVE_STATUS = 'UPDATE_STATICDATA_SAVE_STATUS';

// periodic summary settings

export const UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SHOW_SCREEN = 'UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SHOW_SCREEN';
export const MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_MANAGEMENT_ERROR = 'MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_MANAGEMENT_ERROR';
export const MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_IS_LOADING = 'MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_IS_LOADING';
export const GET_ALL_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS = 'GET_ALL_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS';
export const SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_DETAILS = 'SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_DETAILS';
export const SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_CHANGE = 'SET_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_CHANGE';
export const UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS = 'UPDATE_MODEL_VERSION_PERIODIC_SUMMARY_SETTINGS_SAVE_STATUS';
