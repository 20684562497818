import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';

import {
  GET_ALL_STATICDATA_DETAILS,
  STATICDATA_IS_LOADING,
  STATICDATA_MANAGEMENT_ERROR,
  SET_STATICDATA_CURRENT_PAGE,
  SET_STATICDATA_DETAILS,
  SET_STATICDATA_CHANGE,
  SET_STATICDATA_FILTERED_RESULTS,
  STATICDATA_CLEANUP,
  UPDATE_STATICDATA_SAVE_STATUS
} from '../constants/index';

import { STATICDATA_MANAGEMENT, UPDATE_STATICDATA, CREATE_STATICDATA } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

//StaticData Management
export const setStaticDataErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setStaticDataError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setStaticDataError(messageCode, false));
  }, 2500);
};

export const setStaticDataError = (messageCode, status) => {
  return {
    type: STATICDATA_MANAGEMENT_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const getStaticDataThunk = (widgetCode, unitId, filterObj) => dispatch => {
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(getStaticData(widgetCode, organisationId, unitId, filterObj));
};

export const setLoadingStatus = isLoading => ({
  type: STATICDATA_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getStaticData = (widgetCode, organisationId, unitId, filterObj) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit/staticdata`,
    data: {
      OrganisationId: organisationId,
      UnitId: unitId,
      Filter: filterObj.filter,
      Offset: filterObj.offset,
      Limit: filterObj.limit
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllStaticDataListThunk(retData),
    onFailure: err => setStaticDataErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllStaticDataListThunk = returndata => dispatch => {
  let messageCode = returndata && returndata.message;
  let data = (returndata && returndata.StaticData) || [];
  dispatch(setAllStaticDataList(messageCode, data));
};

export const setAllStaticDataList = (messageCode, data) => ({
  type: GET_ALL_STATICDATA_DETAILS,
  payload: {
    data: {
      staticDataList: data,
      filteredResults: data,
      isLoading: false,
      duplicateSequence: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_STATICDATA_CURRENT_PAGE,
  payload: { currentPage }
});

export const saveStaticDataThunk = (staticData, widgetCode) => dispatch => {
  let url = '/management/unit/staticdata/save';
  let actionName = staticData.StaticDataId === 0 ? CREATE_STATICDATA : UPDATE_STATICDATA;
  let log = logEntry(STATICDATA_MANAGEMENT, actionName, staticData);

  dispatch(saveStaticData(staticData, url, log, widgetCode));
};

export const saveStaticData = (staticData, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}${url}`,
    method: 'POST',
    widgetcode: widgetCode,
    onLoad: status => setLoadingStatus(status),
    onSuccess: message => setStaticDataStatusThunk(message, staticData),
    onFailure: err => setStaticDataErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      StaticData: staticData,
      log: log
    }
  });

export const setStaticDataStatusThunk = (data, staticData) => dispatch => {
  dispatch(setStaticDataStatus(data.message, true));
  setTimeout(() => {
    //Hide the banner
    if (!data.StaticDataId) {
      dispatch(setStaticDataStatus('', false, staticData.IsCopy || false));
    } else {
      dispatch(setStaticDataInsertStatus(data.StaticDataId, false));
    }
  }, 2500);
};

export const setStaticDataInsertStatus = (staticDataId, status) => ({
  type: UPDATE_STATICDATA_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: '',
      staticDataId: staticDataId
    }
  }
});

export const setStaticDataStatus = (messageCode, status, fromCopy = false) => ({
  type: UPDATE_STATICDATA_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: fromCopy ? false : true,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || ''
    }
  }
});

export const describeStaticDataThunk = (staticDataId, unitid, widgetCode) => dispatch => {
  let organisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
  dispatch(describeStaticData(staticDataId, unitid, organisationId, widgetCode));
};

export const describeStaticData = (staticDataId, unitid, organisationId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/management/unit/staticdata`,
    data: {
      UnitStaticDataId: staticDataId || undefined,
      UnitId: unitid,
      OrganisationId: organisationId
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setdescribeStaticDataThunk(retData),
    onFailure: err => setStaticDataErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setdescribeStaticDataThunk = retData => dispatch => {
  dispatch(setDescribeStaticData(retData));
};

export const setDescribeStaticData = retData => ({
  type: SET_STATICDATA_DETAILS,
  payload: retData
});

export const setStaticDataChangeThunk = retData => dispatch => {
  dispatch(setStaticDataChange(retData));
};

export const setStaticDataChange = retData => ({
  type: SET_STATICDATA_CHANGE,
  payload: retData
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_STATICDATA_FILTERED_RESULTS,
  payload: retData
});

export const staticDataCleanUpThunk = staticData => dispatch => {
  dispatch(staticDataCleanUp(staticData));
};

export const staticDataCleanUp = staticData => ({
  type: STATICDATA_CLEANUP,
  payload: {
    data: staticData
  }
});
