import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ApprovedComponent.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ApprovedSubComponent from './ApprovedSubComponent';
import { WidgetVisibility } from '../../utils/widgetManager';

const ApprovedSubComponentTable = ({
  SelectedApprovedComponent,
  setAddHandler,
  setEditHandler,
  disableMode,
  disableModeHandler,
  selectedApprovedSubComponentId,
  selectedIdentifier,
  setApprovedSubComponentList,
  changeApprovedSubComponent,
  setApprovedComponentCustomError,
  componentSubTypeOptions,
  equationOptions,
  userProfileWidgets,
  UpdateAllowed,
  intl
}) => {
  let isEdit = false;

  if (selectedApprovedSubComponentId > 0 || selectedIdentifier.length > 0) {
    isEdit = true;
  }

  const addNewHandler = () => {
    setAddHandler();
    disableModeHandler(true);
  };

  const fetchDataHandler = (approvedSubComponentId, identifier) => {
    setEditHandler(approvedSubComponentId, identifier);
    disableModeHandler(true);
  };

  const deleteDataHandler = (id, identifer) => {
    if (id > -1) {
      const list = SelectedApprovedComponent.ApprovedSubComponentsData || [];
      let result = list.filter(item => {
        if (item.GuidIdentifier === identifer) return item;
      });

      let saveData = result[0];
      saveData.isDeleted = true;
      saveApprovedSubComponentHandler(saveData);
    }
  };

  const saveApprovedSubComponentHandler = subApprovedComponent => {
    let listData = SelectedApprovedComponent.ApprovedSubComponentsData;

    if (subApprovedComponent.ApprovedSubComponentId > 0) {
      const updatedData = listData.map(x =>
        x.GuidIdentifier === subApprovedComponent.GuidIdentifier ? { ...x, ...subApprovedComponent } : x
      );
      setApprovedSubComponentList({
        ApprovedSubComponentsData: updatedData
      });
    } else {
      let hasItem = listData.filter(item => {
        if (item.GuidIdentifier === subApprovedComponent.GuidIdentifier) return item;
      });

      if (hasItem.length > 0) {
        let updatedData = listData.map(x =>
          x.GuidIdentifier === subApprovedComponent.GuidIdentifier ? { ...x, ...subApprovedComponent } : x
        );
        if (subApprovedComponent.isDeleted) {
          updatedData = updatedData.filter(x => x.GuidIdentifier !== subApprovedComponent.GuidIdentifier);
        }
        setApprovedSubComponentList({
          ApprovedSubComponentsData: updatedData
        });
      } else {
        listData.push(subApprovedComponent);
        setApprovedSubComponentList({
          ApprovedSubComponentsData: listData
        });
      }
    }
  };

  const getApprovedSubComponentListData = () => {
    const constantList = SelectedApprovedComponent.ApprovedSubComponentsData || [];
    let constantEquation = constantList.filter(item => {
      if (item.isDeleted !== true) return item;
    });
    return constantEquation;
  };

  const cancelEquationConstantHandler = () => {
    setEditHandler(-1, '');
    disableModeHandler(false);
  };

  const ApprovedSubComponentContent = (
    <ApprovedSubComponent
      isEdit={isEdit}
      selectedApprovedSubComponent={SelectedApprovedComponent.SelectedApprovedSubComponent}
      changeApprovedSubComponent={changeApprovedSubComponent}
      saveApprovedSubComponent={saveApprovedSubComponentHandler}
      cancelHandler={cancelEquationConstantHandler}
      componentSubTypeOptions={componentSubTypeOptions}
      equationOptions={equationOptions}
    />
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{ApprovedSubComponentContent}</td>
    </tr>
  );

  let newRowClass = SelectedApprovedComponent.showAddEditScreen && selectedApprovedSubComponentId === 0 ? s.newRow : '';

  return (
    <div className={s.approvedSubComponentContent}>
      <Row className={s.breakRow}>
        <Col sm={6} lg={8}>
          <h4 className={s.subheading}>
            <FormattedMessage id="approvedComponents.approvedSubComponents" defaultMessage="Approved Sub Components" />
          </h4>
        </Col>
        <Col sm={6} lg={4}>
          <Button
            className={s.addbutton}
            style={WidgetVisibility(userProfileWidgets, 'APPROVEDCOMPONENT_ADDNEW')}
            onClick={addNewHandler}
            disabled={disableMode}
            data-unittest="addGlobalSettingButton"
          >
            + <FormattedMessage id="modelVersion.addNew" defaultMessage="ADD NEW" />
          </Button>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Table variant className={s.innerTable}>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="approvedComponents.subComponentType" defaultMessage="Sub Component Type" />
                </th>
                <th>
                  <FormattedMessage id="approvedComponents.calibrationRequired" defaultMessage="Calibration Required" />
                </th>
                <th>
                  <FormattedMessage id="approvedComponents.output" defaultMessage="Output" />
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {getApprovedSubComponentListData().map(n => {
                return (
                  <Fragment key={n.GuidIdentifier}>
                    <tr key={n.ApprovedSubComponentId} data-unittest="ApprovedSubComponentsData">
                      <td>{n.ApprovedSubComponentName}</td>
                      <td>{n.CalibrationRequired ? 'Yes' : 'No'}</td>
                      <td>{n.Output ? 'Yes' : 'No'}</td>
                      <td>
                        {UpdateAllowed && (
                          <div className={s.buttonContainer}>
                            <Button
                              variant="outline-secondary"
                              className={s.removeButton}
                              href="#"
                              onClick={deleteDataHandler.bind(this, n.ApprovedSubComponentId, n.GuidIdentifier)}
                              disabled={disableMode}
                              data-unittest="deleteData"
                            >
                              <FormattedMessage id="approvedComponents.removeSubComponent" defaultMessage="Remove" />
                            </Button>
                            <Button
                              variant="outline-secondary"
                              className={s.changeButton}
                              href="#"
                              onClick={fetchDataHandler.bind(this, n.ApprovedSubComponentId, n.GuidIdentifier)}
                              disabled={disableMode}
                              data-unittest="changeData"
                            >
                              <FormattedMessage id="approvedComponents.changeSubComponent" defaultMessage="Change" />
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                    {SelectedApprovedComponent.showAddEditScreen && selectedIdentifier === n.GuidIdentifier && editorMain}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
          <div className={newRowClass}>
            {SelectedApprovedComponent.showAddEditScreen && selectedIdentifier === '' && ApprovedSubComponentContent}
          </div>
        </Col>
      </Row>
    </div>
  );
};

ApprovedSubComponentTable.defaultProps = {
  //CalibrationConstants: []
};

ApprovedSubComponentTable.propTypes = {
  setEditHandler: PropTypes.func.isRequired,
  selectedApprovedSubComponentId: PropTypes.number,
  SelectedApprovedComponent: PropTypes.object.isRequired,
  UpdateAllowed: PropTypes.bool,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired
};

export default injectIntl(ApprovedSubComponentTable);
