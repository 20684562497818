import React, { useState } from 'react';
import s from './UserList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Button from 'react-bootstrap/Button';

const UserListTable = ({ adminResetUserPassword, paginatedData, intl, currentPage, UpdateAllowed, ResetAllowed }) => {
  const [active, setActive] = useState(false);
  const [resetId, setResetId] = useState('');
  const [resetEmail, setResetEmail] = useState('');

  const dialogTitle = intl.formatMessage({
    id: 'usermanagment.resetUserPassword',
    defaultMessage: "Are you sure you want to reset this user's password?"
  });

  const handleClose = () => {
    setResetId('');
    setResetEmail('');
    setActive(false);
  };

  const handleConfirm = () => {
    adminResetUserPassword({ id: resetId, email: resetEmail });
    handleClose();
  };

  const resetPasswordHandler = (id, email) => {
    setResetId(id);
    setResetEmail(email);
    setActive(true);
  };

  return (
    <div className="">
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">
              <FormattedMessage id="usermanagment.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="usermanagment.email" defaultMessage="Email" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.usermanagment.contactno" defaultMessage="Contact No" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.active" defaultMessage="Active" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr data-unittest="userlistdata" key={n.UserId}>
                <td className="d-none d-sm-table-cell">{`${n.FullName || ''}`}</td>
                <td>{n.Email || ''}</td>
                <td className="d-none d-md-table-cell">{n.PhoneNumber || ''}</td>
                <td className="d-none d-md-table-cell">{n.IsActive ? 'Yes' : 'No'}</td>
                <td>
                  {UpdateAllowed ? (
                    <Link className={s.selectLink} to={`/admin-controls/user/${n.UserId}`}>
                      <FormattedMessage id="usermanagement.edit" defaultMessage="EDIT" />
                    </Link>
                  ) : null}
                  {ResetAllowed ? (
                    <Button
                      variant="outline-secondary"
                      className={s.resetbutton}
                      onClick={() => resetPasswordHandler(n.GuidIdentifier, n.Email)}
                      data-unittest="resetPasswordHandler"
                    >
                      <FormattedMessage id="usermanagement.resetUserPassword" defaultMessage="RESET PASSWORD" />
                    </Button>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

UserListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(UserListTable);
